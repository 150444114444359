
.headline-app {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 300;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.headline-01 {
  font-size: 3.375rem;
  line-height: 4rem;
  font-weight: 400;
  letter-spacing: 0;
}

.headline-02 {
  font-size: 2.625rem;
  line-height: 3.125rem;
  font-weight: 400;
  letter-spacing: 0;
}

.headline-03 {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 400;
  letter-spacing: 0;
}

.headline-04 {
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0;
}

.headline-05 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 500;
  letter-spacing: 0;
}

.headline-06 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  letter-spacing: 0;
}

.headline-07 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  letter-spacing: 0;
}

.body-01 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
}

.body-02 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0;
}

.body-03 {
  font-size: 0.825rem;
  line-height: 1.125rem;
  font-weight: 400;
  letter-spacing: 0;
}


.label-01 {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.16px;
}

.label-02 {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  letter-spacing: 0.32px;
}
