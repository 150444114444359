@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700&display=swap');
@import url('./typography.css');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'Inter', sans-serif;
  color: #172b4d;
}

.sidebar-button > button {
  margin: 0;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  select,
  select:focus,
  textarea,
  textarea:focus,
  input,
  input:focus {
    font-size: 16px;
  }
}

.appointment {
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* hide scrollbar for chrome, safari and opera */
.appointment::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
